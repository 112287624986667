<script setup>
    const props = defineProps({
        carouselWidth: {
            type: [String, Number],
        },
        stop: {
            type: Boolean,
            default: false,
        },
    });

    const { carouselWidth } = toRefs(props);

    const itemsWrapperRef = ref(null);

    defineExpose({
        itemsWrapperRef,
    })
</script>

<template>
    <section class="simple-carousel">
        <ul
            name="simple"
            :style="`width: ${carouselWidth};`"
            ref="itemsWrapperRef"
        >
            <slot></slot>
        </ul>
    </section>
</template>

<style lang="scss">
    .simple-carousel {
        overflow: hidden;

        ul {
            position: relative;
            display: flex;

            overflow: scroll;

            // overflow-y: hidden;
            // overflow-x: scroll;
            // -webkit-overflow-scrolling: touch;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
           //  -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            scrollbar-width: none;

            width: 100%;
            height: 100%;
            transition: 0.33s ease-in-out;

            li {
                width: 100%;
                flex-grow: 1;
                scroll-snap-align: start;
                display: inline-flex;

                img {
                    background-color: var(--gray-300);
                }
            }
        }

        img,
        li,
        .mess-image {
            transition: width 0.5s ease 0s, height 0.5s ease 0.5s;

            position: relative;
        }

        @media (max-width: $tablet) {
            ul {
                li {
                    display: inline-block;
                }
            }
        }
    }
</style>
